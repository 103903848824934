<template>
    <div id="activityIndexPage">
      <div class="container">
        <div class="section section-2">
          <i class="icon icon-fail"></i>
          <div class="tip">キャンペーンが終了しました</div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: 'ActivityIndex',
  components: {},
  data () {
    return {
    };
  },
  created () {
  },
  mounted () {

  },
  watch: {},
  methods: {

  }
};
</script>

<style scoped lang="less">
  #activityIndexPage {
    .container {
      .section {
        text-align: center;

        &.section-1 {
          .bg {
            height: 658px;
            margin-bottom: 38px;
            background: url("~@/assets/images/bg_15.png") center / contain no-repeat;
          }

          .content {
            .date {
              font-size: 24px;
              line-height: 32px;
              opacity: 0.7;
            }

            .submit-btn {
              width: 694px;
              margin-top: 98px;
            }
          }
        }

        &.section-2 {
          padding-top: 62px;
          padding-bottom: 62px;

          .icon-fail {
            width: 200px;
            height: 200px;
            margin-bottom: 44px;
            background-image: url("~@/assets/images/icon_fail_01.png");
          }

          .tip {
            font-size: 36px;
            line-height: 48px;
            color: #fff;
          }
        }
      }
    }

    .popup-bindPay {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 672px;
      height: 83%;
      max-height: 1118px;

      .close {
        position: fixed;
        top: 3px;
        right: 5px;
        width: 50px;
        height: 50px;
        font-size: 25px;
        line-height: 50px;
        text-align: center;
        border: 1px solid #fff;
        border-radius: 50%;
      }

      .content {
        width: 360px;
        font-size: 30px;
        font-weight: bold;
        line-height: 86px;
        text-align: center;
      }

      .bottom {
        position: fixed;
        bottom: 39px;
        justify-self: flex-end;
        width: 80%;

        .btn {
          width: 100%;
          border-radius: 15px;
        }
      }
    }

    .popup-coupons {
      width: 670px;
      overflow: hidden;
      border-radius: 54px 54px 0 0;

      .popup-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 162px;
        background-image: linear-gradient(180deg, #ff804a 0%, #ff6059 100%);

        .popup-title {
          padding-right: 46px;
          padding-left: 46px;
          font-size: 36px;
          line-height: 46px;
        }
      }

      .content {
        display: flex;
        flex-direction: column;
        max-height: 700px;
        padding: 48px 36px 64px;
        margin-top: -1px;
        background-image: linear-gradient(180deg, #ff804a 0%, #ff6059 100%);

        .tip {
          margin-bottom: 70px;
          font-size: 24px;
          line-height: 32px;
          opacity: 0.7;
        }

        .list {
          height: 100%;
          overflow-y: auto;

          .list-item {
            display: flex;
            align-items: center;
            height: 142px;
            padding-top: 16px;
            padding-bottom: 16px;
            background-color: #fff;
            border-radius: 16px;

            &:not(:last-child) {
              margin-bottom: 20px;
            }

            .left {
              flex: 1;
              align-self: stretch;
              padding: 16px 24px;
              border-right: 2px dashed #ccc;

              .title {
                margin-bottom: 10px;
                font-size: 36px;
                font-weight: bold;
                line-height: 46px;
                color: #333;
              }

              .tip {
                font-size: 24px;
                line-height: 32px;
                color: #666;
              }
            }

            .right {
              flex-shrink: 0;
              width: 172px;
              padding-right: 8px;
              padding-left: 8px;
              font-size: 52px;
              font-weight: bold;
              color: #ee3d19;
              text-align: center;
            }
          }
        }

        .content-footer {
          display: flex;
          flex-shrink: 0;
          align-items: center;
          margin-top: 40px;

          .btn {
            height: 82px;
            border-radius: 16px;
            box-shadow: 0 6px 12px rgba(0, 0, 0, 0.16);

            &.btn-cancel {
              width: 212px;
              margin-right: 20px;
              font-size: 26px;
              color: rgba(255, 255, 255, 0.8);
              background-color: #ff6854;
              border-radius: 16px;
            }

            &.btn-confirm {
              flex: 1;
              font-size: 32px;
              font-weight: bold;
              color: #333;
              background-color: #fac26c;
            }
          }
        }
      }
    }
  }
</style>